import * as React from 'react';

// 1. import `ChakraProvider` component
import {
  ChakraProvider,
  Divider,
  List,
  ListIcon,
  ListItem,
} from '@chakra-ui/react';
import { Box, Text } from '@chakra-ui/react';

import { MdAttachMoney } from 'react-icons/md';
import { BsPeace, BsCurrencyDollar } from 'react-icons/bs';

import logo from '../images/Artboard 1.png';
import fly from '../images/noun_Bird_818904_edited.svg';
import sit from '../images/noun_Bird_edited.svg';
import { motion } from 'framer-motion';

import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/800.css';

import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';

import '../scss/index.scss';
import theme from '../theme/theme';

// markup
const IndexPage = () => {
  return (
    <ChakraProvider>
      <div>
        <Box w="100%" p={'1rem'} color="white">
          {/* <img src={logo} alt="Logo" /> */}
          <iframe
            src="https://ss-metanoia.netlify.app/"
            frameborder="0"
            className="logo-frame"
          ></iframe>
        </Box>
        <div className="fab" boxShadow="xs">
          {/* <img src={fly} alt="Logo" /> */}
          <img className="sit" src={sit} alt="Logo" />
        </div>
        <Box p={'1rem'} w="100%" style={{ position: 'relative', zIndex: 1 }}>
          {/* <motion.div className="container">
            <img src={logo} alt="Logo" />
          </motion.div> */}

          <Text fontSize="4xl">
            a fundamental shift in the way that you think
            <br />
            <br />
            we facilitate empowering mankind, to progress by embracing learning
            as living.
            <Divider my={6} />
            <h4 style={{ fontWeight: '600' }}>
              some missions we have embarked on :
            </h4>
            <br />
          </Text>
          <Text fontSize="3xl">
            <h4>
              <a href="https://metanoiaweblabs.com/">
                <strong>Metanoia Web Labs</strong>
              </a>
              <br />
              facilitating progress with web technologies
            </h4>
          </Text>
          <br />
          <List
            spacing={3}
            fontSize="3xl"
            color={'#8342a2'}
            fontFamily={'Open Sans'}
            fontWeight={100}
          >
            <ListItem>
              <ListIcon fontSize="2xl" color="green.500">
                <BsPeace />
              </ListIcon>
              <strong style={{ fontWeight: '400' }}>forPurpose</strong> -
              Empowering forPurpose (NGO's) Organizations to progress with Web
              technologies
            </ListItem>
            <ListItem>
              <ListIcon fontSize="2xl" color="green.500">
                <BsCurrencyDollar />
              </ListIcon>
              <strong style={{ fontWeight: '400' }}>forProfit</strong> -
              Empowering forProfit Organizations to progress with Web
              technologies to fund ourselves and to achieve sustainable growth
              of the vision
            </ListItem>
          </List>
          <br />
          <Text fontSize="3xl">
            <h4>facilitating mankind to thrive</h4>
            detailed planning is in progress, more details coming soon.
            {/* would appreciate help in certain areas, check job or
            volunteering section to know how to contribute */}
          </Text>
        </Box>
      </div>
    </ChakraProvider>
  );
};

export default IndexPage;
